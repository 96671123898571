import "@fontsource/domine/400.css"
import "@fontsource/domine/700.css"
import "@fontsource/cabin"
import "@fontsource/dm-mono"
import "@fontsource/damion"

export const headingFontFamily = {
	default: `"Domine", serif`
}

export const textFontFamily = {
	default: `"Domine", sans-serif`
}
export const buttonFontFamily = {
	default: `"DM Mono", mono`
}
/*recommended font size system:
(8), 12, 14, 16, 18, 20, 24, 30, 36, 48, 60, 72
base-1, base, base+1
(ti3, ti2,) ti, xs, sm, md, lg, xl, hu, hu2, hu3, hu4, hu5
*/

export const textSizes = {
	xs: "1.2rem",
	sm: "1.4rem",
	md: "1.6rem",
	lg: "1.8rem",
	xl: "2.0rem"
}

export const headingSizes = {
	xs: "1.6rem",
	sm: "2.4rem",
	md: "3.6rem",
	lg: "5.6rem",
	xl: "8.0rem"
}

export const fontSizes = {
	100: "min(max(1rem, 1vw), 1rem)",
	200: "min(max(1.33rem, 1.33rem), 1.33rem)",
	300: "min(max(1.6rem, 1.6vw), 1.6rem)",
	400: "min(max(2.372rem, 2.372vw), 2.372rem)",
	500: "min(max(2.923rem, 3.16vw), 3.16rem)",
	600: "min(max(3.16rem, 3.795vw), 3.795rem)",
	700: "min(max(3.795rem, 5.625vw), 5.625rem)",
	800: "min(max(5.625rem, 7.496vw), 7.496rem)",
	900: "min(max(6.933rem, 9vw), 9rem)"
}

export const lineHeights = {
	100: 1.9,
	200: 1.8,
	300: 1.7,
	400: 1.6,
	500: 1.5,
	600: 1.4,
	700: 1.3,
	800: 1.2,
	900: 1.1
}

fontSizes.h3 = fontSizes[400]
fontSizes.h2 = fontSizes[600]
fontSizes.h1 = fontSizes[800]
lineHeights.h1 = lineHeights[800]
lineHeights.h2 = lineHeights[600]
lineHeights.h3 = lineHeights[400]

export const fontWeight = {
	light: 300,
	regular: 400,
	bold: 700
}
