// require("@fontsource/cabin")
// require("@fontsource/damion")
// require("@fontsource/dm-mono")
// require("@fontsource/domine")

import React from "react"
import { GlobalStyle } from "./src/ziibo/GlobalStyle"

export const wrapPageElement = ({ element }) => {
	return (
		<>
			<GlobalStyle />
			{element}
		</>
	)
}
