import { createGlobalStyle } from "styled-components"
import { fontSizes, headingFontFamily, lineHeights, textFontFamily, buttonFontFamily } from "./fonts"

export const GlobalStyle = createGlobalStyle`
  *,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}
:root {
  --measure: 96ch;
}
html {
    font-size: 62.5%;
}
* {
  max-width: var(--measure);
}

html,
body,
div,
header,
nav,
main,
section,
footer {
  max-width: none;
}

body {
    box-sizing: border-box;
    line-height: ${lineHeights[300]};
    min-height: 100vh;
    background: #ffffff;
    -webkit-font-smoothing: anti-aliased;
    -moz-osx-font-smoothing: anti-aliased;
    text-rendering: optimizeLegibility;
    font-family: ${textFontFamily["default"]};
    font-size: ${fontSizes[300]};
}

h1,h2,h3,h4,h5,h6 {
    font-family: ${headingFontFamily["default"]};
}

h1 {
  font-size: ${fontSizes[800]};
}

h2 {
  font-size: ${fontSizes[600]};
}

h3 {
  font-size: ${fontSizes[400]};
}

h4 {
  font-size: ${fontSizes[400]};
}

h5 {
  font-size: ${fontSizes[400]};
}

h6 {
  font-size: ${fontSizes[300]};
  text-transform: uppercase;
  font-weight: 700;
}


a {
  text-decoration: none;
	color: inherit;
}

button {
    font-family: ${buttonFontFamily["default"]};
}
`
